import React, {Component} from 'react';
import {Container, Header, Message} from 'semantic-ui-react';

export default class Privacy extends Component {
  render() {
    return (
      <div className='terms section' id='AGB'>
        <Container>
          <Header as='h1'>Allgemeine Geschäftsbedingungen</Header>
          <Message>Die Veranstaltung findet vom 17.10.2025 bis 19.10.2025 statt.</Message>
          <Header as='h2'>1. Allgemeine Bestimmungen</Header>
          <div>
            <ol>
              <li>
                Die Veranstalter behalten sich das Recht vor, bei Nichteinhaltung der nachfolgenden AGB den
                Betreffenden von dieser und weiteren Veranstaltungen auszuschließen.
              </li>
            </ol>
          </div>
          <Header as='h2'>2. Voraussetzungen zur Teilnahme</Header>
          <div>
            <ol>
              <li>
                Jeder Teilnehmer muss mindestens das Alter von 16 Jahren erreicht haben.
                Dies gilt sowohl für Gamer als auch für Besucher.
              </li>
              <li>
                Wie wird das Alter der Teilnehmer kontrolliert?
                Die Altersverifizierung erfolgt über das Vorzeigen des Personalausweises beim Einlass.
                Aus diesem Grund solltest Du den Ausweis unbedingt dabei haben.
                Ohne Ausweis können wir Dir leider keinen Zutritt zur Veranstaltung gewähren.
                Das gilt für alle Teilnehmer und auch für eventuelle Besucher, die sich die Veranstaltung ansehen
                möchten.
              </li>
              <li>
                Teilnehmer die zu Beginn der Veranstaltung das Alter von 18 Jahren noch nicht erreicht haben, müssen
                die Veranstaltung bis 24 Uhr verlassen.
                Eine Ausnahme gilt, wenn bei Einlass eine ausgefüllte Erziehungsbeauftragung ("Muttizettel") abgegeben
                wurde, und der Teilnehmer in Begleitung des Erziehungsbeauftragten erscheint.
              </li>
              <li>
                Jeder Teilnehmer hat den nötigen, bekannt gegebenen Teilnahmebeitrag geleistet und dieser ist auch
                eingegangen.
                Sollte es nach Eingang der Bezahlung zu nicht vorhersehbaren Problemen kommen, welche die Teilnahme
                zur Veranstaltung unmöglich machen,
                entscheidet je nach Begründung der Veranstalter, ob 100%, 50% oder 0% vom bezahlten Eintrittspreis
                zurück erstattet werden.
              </li>
              <li>
                Jeder Teilnehmer bringt seinen eigenen Rechner, Monitor, Tastatur, Maus, Joystick, Netzwerkkabel,
                Steckerleiste, Stromkabel etc. mit.
                Für die Betriebsbereitschaft der mitgebrachten Geräte ist der Teilnehmer selbst verantwortlich.
              </li>
              <li>
                Sollte durch Fehler in der zur Verfügung gestellten Technik sich ein Defekt des mitgebrachten
                Inventars mit sich ziehen,
                dann übernimmt der Veranstalter dafür keine Haftung.
              </li>
              <li>
                Den Anweisungen der Veranstalter bezüglich der Inbetriebnahme des persönlichen Rechners und der
                mitgebrachten Geräte ist unbedingt Folge zu leisten.
              </li>
              <li>
                Jeder Teilnehmer erkennt sonstige, separat angekündigte Bestimmungen zur Veranstaltung an und
                akzeptiert mit Betreten der Location die aktuellsten AGB an.
              </li>
            </ol>
          </div>
          <Header as='h2'>3. Bestimmungen während der Veranstaltung</Header>
          <div>
            <ol>
              <li>
                Jeder Teilnehmer verpflichtet sich, die allgemeinen guten Sitten anzuerkennen, und gegen diese nicht
                zu verstoßen.
                Die Veranstalter behalten sich das Recht vor, zu jeder Zeit einen Teilnehmer aus gutem Grund
                auszuschließen.
                Eine Rückzahlung des geleisteten Beitrages zur Veranstaltung erfolgt in diesem Fall nicht.
              </li>
              <li>
                Es wird eindringlich darauf hingewiesen, dass funktionstüchtige Geräte zur Veranstaltung mitgebracht
                werden.
                Dies gilt insbesondere für Stromverteilungsgeräte.
                Bei Zuwiderhandlung behält sich der Veranstalter vor, die betreffenden Personen von der Veranstaltung
                auszuschließen.
              </li>
              <li>
                Jeder Teilnehmer verpflichtet sich, keine gesetzwidrigen Aktivitäten im Rahmen der Veranstaltung zu
                setzen.
                Besonders hervorgehoben werden hier Softwarepiraterie, Verbreitung verbotener Datenbestände (Spiele,
                Filme, Musik, Pornos),
                Beeinträchtigung oder Beschädigung fremder Datenanlagen.
              </li>
              <li>
                Jeder Teilnehmer ist für die Datenbestände auf seinem Rechner selbst verantwortlich.
                Dies gilt besonders für die Einhaltung von Lizenzvereinbarungen sowie für illegale Daten.
                Der Veranstalter übernimmt hierfür keinerlei Verantwortung oder Haftung.
              </li>
              <li>
                Angetrunkene Personen können jederzeit von der Veranstaltung ausgeschlossen werden.
                Das Mitbringen und der Konsum von illegalen Drogen ist strikt verboten.
                Bei Verstoß werden die Teilnehmer sofort der Polizei gemeldet!
              </li>
              <li>
                Der Betrieb von Lautsprechern und Subwoofern ist nicht erlaubt.
                Dies gilt auch für Wasserkocher, Toaster, Mikrowellen oder ähnliche elektronische Geräte.
              </li>
              <li>
                Dem Teilnehmer ist das mutwillige Stören oder Manipulieren der Veranstaltung untersagt.
                Dies gilt im Besonderen für den Betrieb des Computernetzwerks.
              </li>
              <li>
                Beträgt das Mindestalter für die Veranstaltung 16 Jahre, dürfen jugendgefährdende Spiele und Medien
                nur in einem räumlich getrenntem Ü18-Bereich konsumiert werden.
                Steht kein Ü18-Bereich zur Verfügung, ist zur Einhaltung des Jugendschutzes, das Konsumieren von
                jugendgefährdenden Spielen und Medien allen Teilnehmern untersagt.
              </li>
              <li>Jeder Teilnehmer ist für den Schutz und die Sicherheit seines Eigentums selbst verantwortlich.</li>
              <li>Für Wertsachen die während der Veranstaltung entwendet werden bzw. verschwinden, wird keine Haftung
                vom Veranstalter übernommen.
              </li>
              <li>
                Der Veranstalter verpflichtet sich zur Verfügungsstellung und dem Betrieb des Computernetzwerkes und
                auch von Sitzplätzen inkl. Netzwerkanschluss, Strom etc. als Gegenleistung für den Teilnahmebeitrag.
                Kleinere Ausfälle sind situationsbedingt und müssen akzeptiert werden.
                Der Veranstalter verpflichtet sich, etwaige technische Störungen schnellst möglich nach bestem Wissen
                zu beheben.
                Betriebsgarantien sind ausgeschlossen.
              </li>
            </ol>
          </div>
          <Header as='h2'>4. Turniere</Header>
          <div>
            <ol>
              <li>
                Die Teilnahme an den angebotenen Turnierspielen erfolgt freiwillig und berechtigt nicht zum Einfordern
                von Gewinnen.
                Die Teilnahme an diesen Turnieren ist kostenlos.
              </li>
            </ol>
          </div>
          <Header as='h2'>5. Bestimmungen zum Abschluss der Veranstaltung</Header>
          <div>
            <ol>
              <li>
                Jeder Teilnehmer verpflichtet sich persönliche Gegenstände nach der Veranstaltung von seinem Sitzplatz
                zu entfernen (Hardware, Müll etc.).
                Für die Entsorgung des Mülls werden geeignete Säcke usw. vom Veranstalter zur Verfügung gestellt.
              </li>
              <li>Jeder Teilnehmer haftet unmittelbar, im Zweifelsfall mittelbar, für ihm zuordenbaren Schaden
                jeglicher Art.
              </li>
              <li>Es gibt keine Solidarhaftung für alle Teilnehmer.</li>
              <li>
                Der Veranstalter kann für Datenverlust und/oder ähnliche Schäden an Computeranlagen der Teilnehmer,
                der nicht direkt Ihm zuordnenbar ist und aus seinem mutwilligen Verschulden hervorgeht, nicht belangt
                werden.
              </li>
              <li>
                Der Veranstalter wird alle eingetragenen E-mail Adressen an die WWCL bzw. an die EAmasters versenden,
                um die Aufbereitung erreichter Ligapunkte zu ermöglichen.
              </li>
            </ol>
          </div>
          <Header as='h2'>6. Bestimmungen für aufsichtspflichtige Personen</Header>
          <div>
            <ol>
              <li>
                Sollte im Rahmen der Lanparty ein spezielles Nebenevent stattfinden, welches Räumlich getrennt
                realisiert ist,
                kann dies für Gäste unter 18 Jahren ausgeschrieben werden.
                Diese Gäste dürfen sich dann ausschließlich in diesen externen Räumen aufhalten.
              </li>
            </ol>
          </div>
        </Container>
      </div>
    );
  }
}