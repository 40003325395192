import React, { Component, ReactElement } from 'react';
import { Button, Header, Icon, Label, Message, Modal, Table } from 'semantic-ui-react';
import axios from 'axios';

export interface IPaymentTicket {
  id: string,
  username: string,
}

export interface IPaymentOverviewDetails {
  fixedFee: number,
  flexibleFee: number,
  groupTicketPrice: number,
  singleTicketPrice: number,
  userid: string,
  userIsInClan: boolean,
  tickets: IPaymentTicket[],
  removeTicket: ((id: string) => void),
  addTicket: ((id: string, username: string) => void),
}

export interface IPaymentOverviewProps {
  details: IPaymentOverviewDetails,
  trigger: ReactElement,
}

interface IPaymentOverviewState {
  open: boolean,
  isLoading: boolean,
  error: boolean,
}

const emptyState = {
  open: false,
  isLoading: false,
  error: false,
} as IPaymentOverviewState;

export default class PaymentOverviewModal extends Component<IPaymentOverviewProps, IPaymentOverviewState> {
  constructor(props: IPaymentOverviewProps) {
    super(props);

    this.state = {
      open: false,
    } as IPaymentOverviewState
  }

  private static CalculateFee(ticketPrice: number, userCount: number, fixedFee: number, flexibleFee: number): number {
    let sum = ticketPrice * userCount;
    return (fixedFee + sum * flexibleFee) / (1 - flexibleFee);
  }

  private handleOpen(): void {
    let openState = emptyState;
    openState.open = true;
    this.setState(openState);
  }

  private handleClose(): void {
    this.setState({
      open: false,
    })
  }
  
  private handleSubmit(): void {
    const {isLoading} = this.state;
    const {details} = this.props;
    const {tickets, singleTicketPrice, groupTicketPrice, userIsInClan, fixedFee, flexibleFee} = this.props.details;
    
    if(isLoading) {
      return
    }
    
    this.setState({
      isLoading: true, 
    });
    
    const ticketPrice = userIsInClan ? groupTicketPrice : singleTicketPrice;
    const totalFee = PaymentOverviewModal.CalculateFee(ticketPrice, tickets.length, fixedFee, flexibleFee);
    
    var apiTickets = tickets.map((t) => {
      return {
        user_id: t.id,
        ticket_price: ticketPrice,
      }
    });

    axios.post('/api/payment', {
      fees: totalFee,
      tickets: apiTickets,
    })
      .then(response => {
        window.location.assign(response.data);
      })
      .catch(error => {
        this.setState({
          error:true,
          isLoading: false,
        });
      });

  }
  
  renderTable() {
    const {flexibleFee, fixedFee, singleTicketPrice, groupTicketPrice, userid, userIsInClan, tickets, removeTicket} = this.props.details;

    const ticketPrice = userIsInClan ? groupTicketPrice : singleTicketPrice;
    const fee = PaymentOverviewModal.CalculateFee(ticketPrice, tickets.length, fixedFee, flexibleFee);

    return (<Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Nickname
            </Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign='right'>
              Preis
            </Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign='right'>
              Optionen
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tickets.filter(t => t.id === userid).map(ticket => (
            <Table.Row key={ticket.id}>
              <Table.Cell>
                <Header as='h5'>
                  {ticket.username}
                  <Header.Subheader>
                    Das bist du
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell collapsing textAlign='right'>
                {ticketPrice.toFixed(2)} €
              </Table.Cell>
              <Table.Cell collapsing textAlign='right'>
                <Button basic disabled icon='trash' color='red'/>
              </Table.Cell>
            </Table.Row>
          ))}

          {tickets.filter(t => t.id !== userid).map(ticket => (
            <Table.Row key={ticket.id}>
              <Table.Cell>
                <Header as='h5'>
                  {ticket.username}
                  <Header.Subheader>
                    Clanmitglied
                  </Header.Subheader>
                </Header>
              </Table.Cell>
              <Table.Cell collapsing textAlign='right'>
                {ticketPrice.toFixed(2)} €
              </Table.Cell>
              <Table.Cell collapsing textAlign='right'>
                <Button
                  icon='trash'
                  basic
                  color='red'
                  onClick={
                    (e) => {
                      e.preventDefault();
                      removeTicket(ticket.id);
                    }
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.Row className='fee'>
            <Table.Cell>
              Gebühren
              <sup>*</sup>
            </Table.Cell>
            <Table.Cell collapsing textAlign='right'>
              {fee.toFixed(2)} €
            </Table.Cell>
            <Table.Cell>
              &nbsp;
            </Table.Cell>
          </Table.Row>
          <Table.Row className='sum'>
            <Table.Cell>
              Summe
            </Table.Cell>
            <Table.Cell collapsing textAlign='right'>
              {(fee + ticketPrice * tickets.length).toFixed(2)} €
            </Table.Cell>
            <Table.Cell>
              &nbsp;
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>);
  }
  
  render() {
    const {trigger, details} = this.props;
    const {tickets} = details;
    
    if(!tickets) {
      return <div/>
    }
    
    const {open, isLoading, error} = this.state;
    return (
      <Modal
        trigger={trigger}
        centered={false}
        size='small'
        id='PaymentOverview'
        closeIcon
        closeOnDimmerClick={false}
        open={open}
        onOpen={() => this.handleOpen()}
        onClose={() => this.handleClose()}
      >
        <Modal.Header>Warenkorb</Modal.Header>
        <Modal.Content scrolling>
          {/*
          {tickets.length > 0 && this.renderTable()}
          {tickets.length === 0 && <Header as='h4'>Der Warenkorb ist leer</Header>}
          <Message size='tiny' info >
            <sup>*</sup>&nbsp;
            Die Gebühren entsprechen den üblichen PayPal-Gebühren. Diese berechnen sich aus 0,39€ + 2,99% der&nbsp;
            Gesamtsumme. Mehr Informationen zu den PayPal-Gebühren findest du direkt auf der&nbsp;
            <a href='https://www.paypal.com/de/webapps/mpp/merchant-fees' target='_blank'>
              PayPal-Webseite <Icon name='external'/>
            </a>.
          </Message>
          {error &&
          <Message size='tiny' error>
            Leider ist ein Fehler beim Bezahlvorgang aufgetreten. Bitte versuche es später noch einmal, oder melde dich
            bei uns über das Kontaktformular.
          </Message>
          }
          */}
          <Message size='small' error>
            Die Online-Anmeldung zur Clanwars 2025 ist noch nicht gestartet.
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon='cancel'
            content='Abbrechen'
            basic
            onClick={() => this.handleClose()}
          />
          <Button
            icon='paypal'
            content='Weiter zu PayPal'
            color='blue'
            onClick={() => this.handleSubmit()}
            //disabled={isLoading || error || tickets.length === 0}
            disabled={true}
            loading={isLoading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}