import React, {Component} from 'react';
import {Container, Grid, Header, Icon} from 'semantic-ui-react';

export default class Prices extends Component {
  render() {
    return (
      <div className='prices section dark' id='Preise'>
        <Container textAlign='center'>
          <Header as='h1'>
            Eintrittspreise
            <Header.Subheader>Das kostet der Eintritt zur Clanwars 2025.</Header.Subheader>
          </Header>
          <Grid stackable columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='user' circular color='orange' inverted/>
                  Einzelpreis
                  <Header.Subheader>
                    Einzelne Personen zahlen:<br />
                    <Icon name='circle' size='small' /> Vorverkauf ??€<sup>1</sup><br />
                    <Icon name='circle' /> Abendkasse ??€
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='users' circular color='orange' inverted/>
                  Gruppenpreis
                  <Header.Subheader>
                    Gruppen/Clans ab 4 Personen zahlen pro Person:<br />
                    <Icon name='circle' size='small' /> Vorverkauf ??€<sup>1</sup><br />
                    <Icon name='circle' /> Abendkasse ??€
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='child' circular color='orange' inverted/>
                  Besucher
                  <Header.Subheader>
                    Besucher der Clanwars 2025 zahlen:<br />
                    <Icon name='circle' /> Abendkasse (Spende in Spendenschwein)
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className='text-muted'>
            <p>Der Nachlass für Gruppenkarten wird gewährt, sobald bei der Bezahlung ein Clan im Profil hinterlegt ist. Am Einlass wird geprüft, ob mehr als 4 Personen innerhalb des Clans bezahlt haben. Ist dies nicht der Fall, muss der Differenzbetrag von 5€ an der Abendkasse nachträglich gezahlt werden.</p>
            <p><sup>1</sup> bei Zahlung über PayPal fallen zusätzliche Gebühren an.</p>
          </div>
        </Container>
      </div>
    )
  }
}