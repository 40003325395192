import React, { Component } from 'react';
import { Container, Button, Header, Table, Label, Image, Icon, Checkbox } from 'semantic-ui-react';
import Helpers from '../../Helpers';
import { IInClan } from './index';
import { IPaymentOverviewDetails } from './PaymentOverviewModal';
import { UserInfo } from '../../types';

interface IMemberProps {
  clan: IInClan,
  userInfo: UserInfo,
  paymentDetails: IPaymentOverviewDetails,
}

export default class Member extends Component<IMemberProps, any> {
 
  private checked(id: string): boolean {
    const {paymentDetails} = this.props;
    
    return paymentDetails.tickets.find(t => t.id === id) !== undefined;
  }

  private handleCheckBoxChange(id: string, name: string, checked: boolean): void {
    const {paymentDetails} = this.props;
    if(checked) {
      paymentDetails.addTicket(id, name);
    } else {
      paymentDetails.removeTicket(id);
    }
  }
  
  render() {
    const {clan, userInfo, paymentDetails} = this.props;
    
    if(!clan.members) {
      return <div/>
    }
    
    const users = clan.members.map((member, idx) => {return {
      id: member.id,
      name: member.name,
      joined: new Date(member.join_date),
      isAdmin: member.is_admin,
      payed: member.payed,
    }});
    
    return (
      <Container>
        <Header as='h2'>{clan.name}</Header>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>
                &nbsp;
              </Table.HeaderCell>
              <Table.HeaderCell>
                Mitglied
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Bezahlstatus
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Bezahlen
              </Table.HeaderCell>
              {clan.is_admin && false && // temporarily not needed
                <Table.HeaderCell textAlign='right'>
                  Optionen
                </Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users.map((user, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                    &nbsp;
                </Table.Cell>
                <Table.Cell>
                  <Header as='h4'>
                    {user.name}
                    {user.isAdmin ? <small> - Admin</small> : ''}
                    <Header.Subheader>
                      Beigetreten am: {Helpers.getDateString(user.joined)}
                    </Header.Subheader>
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {user.payed
                    ? <Label color='green'>bezahlt</Label>
                    : <Label color='red'>nicht bezahlt</Label>
                  }
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {user.payed
                    ? ' '
                    : <Checkbox
                        onChange={(event, data) => this.handleCheckBoxChange(user.id, user.name, data.checked as boolean)}
                        checked={this.checked(user.id)}
                        //disabled={user.id === userInfo.id}
                        disabled={true}
                        toggle
                      /> 
                  }
                </Table.Cell>
                {clan.is_admin && false && // Temporarily not needed
                  <Table.Cell textAlign='right'>
                    <Button
                      size='small'
                      basic color='orange'
                      icon
                      disabled={user.isAdmin}
                    >
                      <Icon name='wrench'/>
                    </Button>
                    <Button
                      size='small'
                      basic color='red'
                      icon
                      disabled={user.isAdmin}
                    >
                      <Icon name='trash'/>
                    </Button>
                  </Table.Cell>
                }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}