import React, {Component} from 'react';
import { Button, Container, Header, Icon, Message } from 'semantic-ui-react';
import PaymentOverviewModal, { IPaymentOverviewDetails } from './PaymentOverviewModal';
import Contact from '../Layout/Contact';

export interface IPaymentProps {
  paymentDetails: IPaymentOverviewDetails,
}

export default class Payment extends Component<IPaymentProps, any> {
  render() {
    const {paymentDetails} = this.props;
    
    const trigger = (
      <Button basic color='orange' icon>
        <Icon name='shopping cart'/> Zum Warenkorb
      </Button>
    );

    return (
      <div className='payment section light' id='Bezahlen'>
        <Container>
          <Header as='h2'>
            Bezahlen
          </Header>

          <Message size='small' info>
            <Message.Header>Die Anmeldung zur Clanwars 2025 ist noch nicht gestartet</Message.Header>
            <p>Die Online-Anmeldung zur Clanwars 2025 noch nicht gestartet, bitte schaue später noch einmal vorbei.</p>
          </Message>
          {/*
          <Message size='small' info>
            <Message.Header>Hinweise zum Bezahlsystem</Message.Header>
            <p>Du bist erst offiziell zur Clanwars angemeldet, wenn dein Bezahlstatus auf "bezahlt" steht, solange der Status auf "nicht bezahlt" steht, gehen wir davon aus, dass du/das Clan-Mitglied nicht erscheinen wird.</p>
            <p>Es ist möglich, auch für andere Clan-Mitglieder zu bezahlen. Wähle dazu einfach in der Tabelle oben die Mitglieder aus und gehe anschließend zum Warenkorb.</p>
            <p>Es ist jetzt auch möglich, mehrere Bestellungen aufzugeben. Hast du zum Beispiel schon bezahlt und ein Clan-Mitglied von dir hat sich erst später registriert, kannst du später noch für Ihn bezahlen.</p>
            <p>Aktuell bieten wir nur PayPal über unser System an. Möchtest du per Überweisung bezahlen, kannst du uns über das <Contact/>-Formular anschreiben.</p>
          </Message>
          */}
          <PaymentOverviewModal details={paymentDetails} trigger={trigger}/>
          <Button basic icon color='orange' disabled>
            <Icon name='list' /> Bezahlübersicht
          </Button>
        </Container>
      </div>
    );
  }
}