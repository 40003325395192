import React, {Component} from 'react';
import { Container, Menu, Header, Dropdown, Icon, Label, Sidebar, Segment } from 'semantic-ui-react';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import Login from './Login';
import ChangePassword from './ChangePassword';
import { UserInfo } from '../../types';
import PaymentOverviewModal, { IPaymentOverviewDetails } from '../Clan/PaymentOverviewModal';

interface SpyItem {
  id: string;
  inView: boolean;
}

export interface NaviationProps {
  login: (
    (
      user: UserInfo,
      keepLogin: boolean,
    ) => void
  ),
  paymentDetails: IPaymentOverviewDetails,
  logout: (() => void),
  user: UserInfo,
}

interface NavigationState {
  items: SpyItem[],
  navItems: string[],
  isLoggedIn: boolean,
  showSideBar: boolean,
}

export default class Navigation extends Component<NaviationProps, NavigationState> {
  private _timer: number;

  constructor(prop: NaviationProps) {
    super(prop);
    this._timer = 0;

    this.state = {
      items: [],
      navItems: ['Infos', 'Spiele', 'Galerie', 'Preise', 'Karte', 'Anmeldung'],
      isLoggedIn: false,
      showSideBar: false,
    };
  }

  private isInView = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const middle = window.innerHeight / 2;
    return rect.top < middle && rect.bottom > middle;
  };

  private spy() {
    clearInterval(this._timer);
    this._timer = window.setTimeout(() => {
      const {items, navItems} = this.state;

      items.forEach(item => {
        const element = document.getElementById(item.id);
        item.inView = element ? this.isInView(element) : false;
      });

      this.setState({
        items: items
      });
    }, 10);
  }
  
  private setItems(navItems: string[]): void {
    
  }
  
  private toggleSideBar(show: boolean) : void {
    this.setState({
      showSideBar: show
    });
  }

  public componentDidMount(): void {
    setInterval(() => {
      document.addEventListener("scroll", () => this.spy());
      const {items, navItems} = this.state;
      const newItems: SpyItem[] = [];
      
      let changed = false;
      
      navItems.forEach(id => {
        const element = document.getElementById(id);
        
        const item = {
          id: id,
          inView: element ? this.isInView(element) : false,
        } as SpyItem;
        
        const oldItem = items.find(i => i.id == item.id);
        
        if(!oldItem || oldItem.inView !== item.inView)
        {
          changed = true;
        }

        newItems.push({
          id: id,
          inView: element ? this.isInView(element) : false,
        } as SpyItem);
      });

      if(changed) {
        this.setState({
          items: newItems
        });
      }
    }, 100);
  }
  
  public componentWillReceiveProps(nextProps: Readonly<NaviationProps>, nextContext: any): void {
    const newNavItems = nextProps.user.id ? ['Clan', 'Bezahlen', 'FAQ']:['Infos', 'Spiele', 'Galerie', 'Preise', 'Karte', 'Anmeldung'];
    
    this.setState({
      navItems: newNavItems,
    });
  }

  public componentWillUnmount(): void {
    document.removeEventListener("scroll", () => this.spy());
  }
  
  private login(user: UserInfo, keepLogin: boolean): void {
    const {login} = this.props;
    
    this.setState({
      showSideBar: false
    });
    
    login(user, keepLogin);
  }
  
  private logout(): void {
    const {logout} = this.props;
    
    this.setState({
      showSideBar: false
    });
    
    logout();
  }
  
  render() {
    const {items, isLoggedIn, showSideBar} = this.state;
    const {user, paymentDetails} = this.props;

    const trigger = (
      <Menu.Item>
        <Icon name='shopping cart'/>
        {
          paymentDetails.tickets && paymentDetails.tickets.length > 0 && (
            <Label circular color='orange' floating className='shopping-cart'>
              {paymentDetails.tickets.length}
            </Label>
          )
        }
      </Menu.Item>
    );

    return (
      <div>
        <Menu inverted fixed='top' size='massive'>
          <Container>
            <Menu.Item
              as={NavLink}
              to='/'
              active={false}
            >
              Clanwars 2025
            </Menu.Item>
            <Menu.Menu position='left'>
              {items.map((item, k) => {
                return (
                  <Menu.Item
                    as={NavLink}
                    to={`/#${item.id}`}
                    smooth
                    active={item.inView}
                    key={k}
                  >
                    {item.id}
                  </Menu.Item>
                )
              })}
            </Menu.Menu>
            <Menu.Menu position='right'>
              {user.id &&
                <PaymentOverviewModal trigger={trigger} details={paymentDetails}/>
              }
              {user.id &&
                <Dropdown item simple text={user.username}>
                  <Dropdown.Menu>
                    <Dropdown.Header>{user.username}</Dropdown.Header>
                    <Dropdown.Divider/>
                    <ChangePassword trigger={
                      <Dropdown.Item>
                        <Icon name='setting'/>
                        Passwort ändern
                      </Dropdown.Item>
                    }/>
                    <Dropdown.Item onClick={() => this.logout()}>
                      <Icon name='log out'/>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
              {!user.id &&
                <Login login={(user, keepLogin) => this.login(user, keepLogin)}/>
              }
            </Menu.Menu>
            <Menu.Menu className='mobile'>
              <Menu.Item onClick={() => this.toggleSideBar(!showSideBar)}>
                <Icon name='bars'/>
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        <div className='header-placeholder'/>
          <Sidebar
            as={Menu}
            animation='overlay'
            direction='right'
            inverted
            onHide={() => this.toggleSideBar(false)}
            vertical
            visible={showSideBar}
            wdith='thin'
          >
            <Menu.Item className='head'>
              <Header as='h4' inverted>
                Menü
              </Header>
            </Menu.Item>

            {items.map((item, k) => {
              return (
                <Menu.Item
                  as={NavLink}
                  to={`/#${item.id}`}
                  smooth
                  active={item.inView}
                  key={k}
                  onClick={() => this.toggleSideBar(false)}
                >
                  {item.id}
                </Menu.Item>
              )
            })}
            <Menu.Item className='head'>
              <Header as='h4' inverted>
                Nutzer
              </Header>
            </Menu.Item>

            {user.id &&
              <>
                <ChangePassword trigger={<Menu.Item>Passwort ändern</Menu.Item>} />
                <Menu.Item onClick={() => this.logout()}>Logout</Menu.Item>
              </>
            }
            {!user.id &&
              <Login login={(user, keepLogin) => this.login(user, keepLogin)}/>
            }
          </Sidebar>
      </div>
    );
  }
}