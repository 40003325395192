import React, {Component} from 'react';
import Informations from './Informations';
import Games from './Games';
import Gallery from './Gallery';
import Prices from './Prices';
import Map from './Map';
import Register from './Register/index';
import { Image } from 'semantic-ui-react';

export default class Landing extends Component<any, any> {
  render() {
    return (
      <div>
        <Image src='/images/header-bg-2025.jpg'/>
        <Informations/>
        <Games/>
        <Gallery/>
        <Prices/>
        <Map/>
        <Register/>
      </div>
    );
  }
}