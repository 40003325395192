import React, {Component} from 'react';
import {Container, Grid, Header, Icon} from 'semantic-ui-react';

export default class Informations extends Component {
  render() {
    return (
      <div className='section light' id='Infos'>
        <Container textAlign='center'>
          <Header as='h1'>
            Clanwars 2025
            <Header.Subheader>Die wichtigsten Infos auf einen Blick.</Header.Subheader>
          </Header>
          <Header as='h2'>
            Die Clanwars 2025 beginnt am 17.10.2025 - 18:00 Uhr
          </Header>
          <Grid stackable columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='list ol' circular color='orange' size='massive' inverted/>
                  Turniere
                  <Header.Subheader>
                    Messt euch in den Turnieren und seht wer am Ende der Beste ist.<br/>
                    Natürlich winken dem Gewinner am Ende attraktive Preise.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='users' circular color='orange' inverted/>
                  Community
                  <Header.Subheader>
                    Kein Bock auf Turniere? Dann trefft alte Freunde und schließt neue Bekanntschaften.<br/>
                    Der Spaß steht im Mittelpunkt!
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='laptop' circular color='orange' inverted/>
                  Gaming
                  <Header.Subheader>
                    Spiele dürfen auf einer LAN-Party logischerweise nicht fehlen.<br/>
                    Was unter anderem gespielt wird, findet ihr weiter unten, unter Spiele.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='sitemap' circular color='orange' inverted/>
                  Netzwerk
                  <Header.Subheader>
                    100MBit im Clientbereich mit einer GigaBit-Backbone sorgen für einen flüssigen und lag-freien Spielablauf.<br/>
                    Inkl. WLAN für eure Smartphones.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='utensils' circular color='orange' inverted/>
                  Catering
                  <Header.Subheader>
                    Wie jedes Jahr bieten wir euch ein zahlreiches Angebot an Speisen und Getränken zu gewohnt günstigen Preisen an.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h3' icon>
                  <Icon name='euro sign' circular color='orange' inverted/>
                  Preise
                  <Header.Subheader>
                    Der Eintritt ist ebenfalls gewohnt günstig, als Clan/Gruppe könnt ihr extra sparen.<br/>
                    Genauere Infos findet ihr unter Preise.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}